import React from "react"
import PageLayout from "../components/pageLayout"

const PrivacyPolicy = () => {
  return (
    <PageLayout seo={{ title: "Privacy Policy" }}>
      <h1>Privacy Policy</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quis diam
        volutpat, pulvinar dui ac, blandit ligula. In eu mauris neque. Sed vitae
        euismod velit. Nullam fermentum, leo sit amet tincidunt elementum, orci
        mauris sagittis nibh, eget efficitur enim turpis quis est.
      </p>

      <h2>Donec eget justo</h2>
      <p>
        ut sit amet diam. Duis et nisi gravida, iaculis erat vel, imperdiet
        quam. Nam ac ex tristique, auctor tellus in, rhoncus odio. Vestibulum
        sed nulla vitae mi ullamcorper imperdiet ut quis mauris. Proin id
        ullamcorper enim, sit amet elementum dui. Nulla in malesuada enim. Nulla
        vel consequat risus.
      </p>
      <h3>Curabitur consequat</h3>
      <p>
        Magna in viverra tristique. Sed venenatis eleifend placerat. Donec
        laoreet quam ex, ac posuere lectus congue vel. Donec sagittis est id
        fermentum varius. Nulla vitae purus erat.
      </p>
      <ul>
        <li>Praesent ac nisl in dui mollis luctus eget eu mauris.</li>
        <li>Donec rhoncus cursus libero et condimentum.</li>
        <li>
          Integer quis lectus eu lorem aliquam consectetur non vel dui. Proin
          efficitur diam a egestas accumsan.
        </li>
        <li>
          Aliquam ut vehicula est. Etiam ultricies aliquet enim, vitae tincidunt
          tortor efficitur sit amet.
        </li>
        <li>Proin congue ex vitae nibh tempus, in porta est fringilla.</li>
        <li>
          Duis at consequat massa. Phasellus quis ante efficitur, dictum lorem
          a, gravida lorem. Proin et massa id ipsum venenatis posuere et sed mi.
          Nullam non fringilla diam. Nunc finibus velit tempor diam
          sollicitudin, semper varius justo accumsan.
        </li>
      </ul>
      <p>
        Cras euismod, neque ut tempus euismod, nibh ex facilisis justo,
        fermentum molestie dui dui sed neque. Nullam interdum magna tortor, eget
        ornare justo vehicula vitae. Ut ut pharetra lorem, sed consequat quam.
        Morbi egestas ipsum eu nulla aliquam ornare. Integer commodo eros metus,
        eu molestie nibh consequat a. Vivamus varius semper porta. Vestibulum
        porta orci in dui facilisis dignissim. Phasellus congue odio vitae
        gravida tempor. Cras mollis lacus arcu, nec commodo ante volutpat vitae.
      </p>
      <p>
        Nam et iaculis nulla. Suspendisse enim ipsum, luctus nec mauris sit
        amet, tincidunt congue purus. Etiam cursus pharetra tortor eu tincidunt.
        Vivamus sit amet dui nulla. Vivamus egestas neque eget quam tempor, id
        viverra nulla porttitor. Mauris eu luctus nibh. Duis quis rhoncus orci,
        et tristique nibh. Curabitur egestas quam eros, et eleifend ex malesuada
        porttitor. Class aptent taciti sociosqu ad litora torquent per conubia
        nostra, per inceptos himenaeos.
      </p>
    </PageLayout>
  )
}

export default PrivacyPolicy
